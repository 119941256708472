import React from 'react'

export const PlanDeinDing = () => {
    return (
        <div>
            
        </div>
    )
}
export default PlanDeinDing
